<script
    src="../../../../../../../AppData/Local/Temp/Rar$DRa14036.14120/src/app/helper/alertTimer/alert-timer.service.ts"
>

</script>
<template>
  <!-- The Modal -->
  <div
      id="resetPasswordModal"
      class="modal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <button
              type="button"
              class="close"
              data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="">
          <!--Start-->
          <b-card-code
              title="Reset Password"
          >
            <!-- form -->
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>

                  <b-col md="12">
                    <b-form-group>
                      <label for="current_password">Current Password</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Current Password"
                          rules="required"
                      >
                        <b-form-input
                            type="password"
                            v-model="updatedObject.currentPassword"
                            name="current_password"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="password">New Password</label>
                      <validation-provider
                          #default="{ errors }"
                          name="password"
                          rules="required|min:6"
                      >
                        <b-form-input
                            type="password"
                            v-model="updatedObject.newPassword"
                            name="password"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="password_confirmation">Confirm Password</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Password Confirmation"
                          rules="required|confirmed:password"
                      >
                        <b-form-input
                            type="password"
                            v-model="updatedObject.newPasswordConfirmation"
                            name="password_confirmation"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Modal footer -->
                <div class="modal-footer">
                  <button
                      type="button"
                      class="btn btn-primary"
                      @click.prevent="validationForm"
                  >
                    Submit
                  </button>
                </div>
              </b-form>
            </validation-observer>

            <template #code>
              {{ codeMultiple }}
            </template>
          </b-card-code>
          <!--End-->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BFormTextarea, BFormFile, BCardText, BFormCheckbox

} from 'bootstrap-vue'
import {
  required, email, confirmed, password,
} from '@validations'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import { codeMultiple, codeBasic } from './code'
import FormTextAreaDefault from '../../../shared/form-textarea/FormTextAreaDefault.vue'
import Translations from '../../../shared/translations/Translations.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Spinner from '@/shared/loader/Spinner'
import ProfileService from '@/services/profileService'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCardCode,
    BFormFile,
    Translations,
    Spinner,
    BCardText,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    FormTextAreaDefault,
    vSelect,
    vueDropzone: vue2Dropzone,
  },
  props: ['item'],
  data() {
    return {
      userRole: '',
      areas: [],
      types: ['Lab', 'Clinic', 'Hospital'],
      initPosition: {},
      organizations: [],
      cities: [],
      updatedObject: {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
      },
      required,
      password,
      email,
      confirmed,
      codeMultiple,
      codeBasic,
      userData: getUserData(),
    }
  },
  mounted() {
    this.userRole = getUserData().role
    this.$watch(() => this.item, val => {
      this.updatedObject = {
        ...val,
        name: val.name,
        id: val.id,
      }
      console.log("profile",this.updatedObject )
    })
  },
  methods: {
    validationForm() {
        console.log(this.updatedObject)
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              ProfileService.password(this.updatedObject)
                  .then(response => {
                    if (response && response.status === 200) {
                      this.$emit('refresh', 'true')
                      this.updatedObject = {
                        currentPassword: '',
                        newPassword: '',
                        newPasswordConfirmation: '',
                      }
                      $('#resetPasswordModal').modal('hide')
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Success',
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: `The password has been reset successfully !`,
                        },
                      })
                    }

                  })
            }
          })
    },
    resetDropzone() {
      this.$refs.createRef.removeAllFiles()
    },
    handleLanguage(lang) {
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

label {
  font-weight: bold;
}
</style>
