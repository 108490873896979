import AbstractService from '@/services/abstractService'

class ProfileService extends AbstractService {
    endpoint = 'profile'

    password(data) {
        const formData = new FormData()
        for (const key in data) {
            formData.append(key, data[key])
        }
        return this.http.post(`${this.endpoint}/change-password`, formData)
    }
    put(id, data, params = null) {
        // define append form
        const formData = new FormData()

        for (const key in data) {
            if ((key === 'image' && data[key] === undefined ) || (key === 'image' && data[key] === null)) {
                continue
            }
            formData.append(key, data[key])
        }
        return this.http.put(`${this.endpoint}/${id}`, formData, params)
    }
}

const Service = new ProfileService()

export default Service
