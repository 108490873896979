<template>
  <div>
    <div
      v-if="loading === false"
      class="row"
    >
      <div class="col-md-6" />
      <div class="col-md-6 create-button">
        <button
          type="button"
          class="btn btn-primary mb-2 ml-1"
          data-toggle="modal"
          data-target="#resetPasswordModal"
          @click="reInit = !reInit"
        >
          Reset Passowrd
        </button>
        <reset-password-modal
          :item="profile"
          @refresh="getItems()"
        />
      </div>
    </div>
    <Spinner v-if="loading === true" />
    <div v-else="loading === false">
      <b-card-code title="Profile">
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <label for="email">Email</label>
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="userData.email"
                      name="email"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <label for="name">Name</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    rules=""
                  >
                    <b-form-input
                      id="name"
                      v-model="userData.full_name"
                      name="name"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
<!--            <b-row>-->
<!--              <b-col md="12">-->
<!--                <b-form-group>-->
<!--                  <label for="image">Image</label>-->
<!--                  <validation-provider-->
<!--                    #default="{ errors }"-->
<!--                    name="image"-->
<!--                    rules=""-->
<!--                  >-->
<!--                    <vue-dropzone-->
<!--                      id="image"-->
<!--                      ref="profileImageRef"-->
<!--                      name="icon"-->
<!--                      :options="dropzoneOptions"-->
<!--                    />-->
<!--                    <small class="text-danger">{{ errors[0] }}</small>-->
<!--                  </validation-provider>-->
<!--                </b-form-group>-->
<!--              </b-col>-->

<!--            </b-row>-->

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                @click.prevent="validationForm"
              >
                Submit
              </button>
            </div>

          </b-form>
        </validation-observer>
      </b-card-code>
    </div>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BFormTextarea, BFormDatepicker, BFormCheckbox, BFormFile, BCardText,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { getSkinColor, getUserData } from '@/auth/utils'
import {
  required, email, confirmed, password,
} from '@validations'
import ProfileService from '@/services/profileService.js'
import Spinner from '@/shared/loader/Spinner'
import vue2Dropzone from 'vue2-dropzone'
import ResetPasswordModal from './resetPassword.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BForm,
    BCol,
    BFormTextarea,
    BFormDatepicker,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormFile,
    BCardText,
    Spinner,
    ResetPasswordModal,
    vueDropzone: vue2Dropzone,

  },
  data() {
    return {
      reInit: false,
      userData: getUserData(),
      required,
      email,
      loading: false,
      profile: '',
      cities: [],
      areas: [],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        addRemoveLinks: false,
        acceptedFiles: '.jpeg,.jpg,.png,.gif, .svg',
        dictRemoveFile: 'Remove',
        maxFiles: 1,
        init() {
          this.on('maxfilesexceeded', function (file) {
            this.newFile = true
            this.removeAllFiles()
            this.addFile(file)
          })
        },
      },

    }
  },
  mounted() {
    console.log(this.userData)
    this.getItems()
    this.$watch(() => this.profile, profile => {
      // if (profile.avatarFile.length > 0) this.$refs.profileImageRef.manuallyAddFile(profile.avatarFile[0].file, profile.avatarFile[0].url)

      this.userData.full_name = profile.fullName
      document.getElementById('admin-name').innerText = this.userData.fullName
      // this.userData.avatarUrl = profile.avatarUrl
      // document.getElementById('admin-avatar').setAttribute('src', this.userData.avatarUrl)

      console.log('[[[', this.userData)
    })
  },
  methods: {
    getItems() {
      this.loading = true
      ProfileService.show(this.userData.id).then(response => {
        this.profile = response.data.data
        console.log('profile', this.profile)

        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },
    validationForm() {
      console.log(this.userData.id, this.userData)
      this.$refs.simpleRules.validate()
        .then(success => {
          if (success) {
            // this.userData.image = this.$refs.profileImageRef.getAcceptedFiles()[0]

            ProfileService.put(this.userData.id, this.userData)
              .then(response => {
                if (response && response.status === 200) {
                  this.getItems()

                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: 'Profile has been updated successfully !',

                    },
                  })
                }
              })
          }
        })
    },

  },
}
</script>
